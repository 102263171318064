import React, { useEffect, useRef, useState, useLayoutEffect } from 'react'
import { PageProps } from 'gatsby'
import { format, parseISO } from 'date-fns'
import Layout from 'src/components/Layout'
import BreadCrumb from 'src/components/Breadcrumb'
import BlogArticle from 'src/components/BlogArticle'
import AddThis from 'src/components/AddThis'
import CategoryTab from 'src/components/Tabs/CategoryTab'
import LoadingIndicator from 'src/components/LoadingIndicator'
import CategorySelect from 'src/components/Select/CategorySelect'

import useScroll from 'src/hooks/useScroll'
import useWidth from 'src/hooks/useWidth'

import isVisible from 'src/utils/isVisible'

import categoriesJSON from 'src/assets/data/categories.json'

import { Category, SubCategory } from 'src/types/Category'

import { Banner } from './style'
import SEO from 'src/components/Seo'

type Article = {
  id: string;
  slug: string;
  title: string;
  introduction: string;
  grid_image_url: string;
  published_at: string;
  updated_at: string;
}

type SubCategoryProps = {
  pageContext: {
    category: string;
    subcategory: string;
    articles: Article[];
  };
} & PageProps

const WIDTH_MD = 768

const SubCategoryTemplate = ({ pageContext }: SubCategoryProps) => {
  const scroll = useScroll(300)
  const loadingRef = useRef<SVGSVGElement>(null)
  const limit = 8
  const [ offset, setOffset ] = useState(limit)
  const [ loadingVisible, setLoadingVisible ] = useState(true)
  const [ isMobile, setIsMobile ] = useState(false)
  const windowWidth = useWidth(300)
  const category = categoriesJSON.find((category: Category) => category.slug === pageContext.category)
  const subCategory = (category as Category).subcategories.find((subCategory: SubCategory) => subCategory.slug === pageContext.subcategory)
  const quantArticles = pageContext.articles.length

  const breadcrumbPath = [
    {
      text: 'Blog',
      link: '/',
    },
    {
      text: (category as Category).name,
      link: `/${(category as Category).slug}`,
    },
    {
      text: (subCategory as SubCategory)?.name,
      link: `/${(subCategory as SubCategory)?.slug}`,
    },
  ]

  useEffect(() => {
    if (isVisible(loadingRef.current) && (offset <= quantArticles)) {
      setOffset(offset + limit)
      setLoadingVisible(false)
    } else if (offset > quantArticles) {
      setLoadingVisible(false)
    } else {
      setLoadingVisible(true)
    }
  }, [ scroll ])

  useLayoutEffect(() => {
    setIsMobile(windowWidth <= WIDTH_MD)
  }, [ windowWidth ])

  return (
    <Layout>
      <SEO
        title={`${subCategory?.name} · Blog do Inter`}
        url={`${process.env.BASE_URL}/${subCategory?.slug}/`}
        meta={[
          {
            name: 'description',
            content: subCategory?.description,
          },
          {
            property: 'og:title',
            content: `${subCategory?.name} · Blog do Inter`,
          },
          {
            property: 'og:description',
            content: subCategory?.description,
          },
          {
            property: 'og:locale',
            content: 'pt_BR',
          },
          {
            property: 'og:type',
            content: 'website',
          },
          {
            property: 'og:url',
            content: `${process.env.BASE_URL}/${category?.slug}/${subCategory?.slug}`,
          },
          {
            property: 'og:site_name',
            content: 'Banco Inter',
          },
          {
            property: 'twitter:site',
            content: '@bancointer',
          },
          {
            property: 'twitter:creator',
            content: '@bancointer',
          },
        ]}
      />
      <h1 className='sr-only'>{subCategory?.name} · Blog do Inter</h1>

      <Banner className='d-flex'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-12 mb-3'>
              <h2 className='fs-24 lh-28 fw-600 text-white'>{category?.name}</h2>
            </div>
          </div>
        </div>
      </Banner>

      <section id='compartilhe' className='d-flex bg-grayscale--100 py-3 justify-content-center align-items-center h-breadcrumb'>
        <div className='container'>
          <div className='row justify-content-center align-items-center'>
            <div className='col-12 col-lg-9'>
              <BreadCrumb path={breadcrumbPath} />
            </div>
            <div className='col-6 col-lg-3 text-right px-0 pr-lg-2'>
              <AddThis />
            </div>
          </div>
        </div>
      </section>

      <section id='artigos-do-blog' className='pt-4 pb-5'>
        <div className='container'>
          {
            !isMobile ? (
              <CategoryTab activeTab={pageContext.category} activeSubTab={pageContext.subcategory} items={categoriesJSON} />
            ) : (
              <CategorySelect activeTab={pageContext.category} activeSubTab={pageContext.subcategory} items={categoriesJSON} />
            )
          }
          <div className='row'>
            <div className='col-12'>
              <div className='row'>
                {
                  pageContext.articles.slice(0, offset).map((article: Article) => {
                    return (
                      <div key={article.id} className='col-12 col-md-4'>
                        <BlogArticle
                          link={`/${article.slug}/`}
                          image={article.grid_image_url}
                          category={(category as Category).name}
                          subCategory={subCategory?.name}
                          title={article.title}
                          date={format(parseISO(article.published_at), 'dd/MM/yyyy')}
                          description={article.introduction}
                        />
                      </div>
                    )
                  })
                }
              </div>
              <div className='d-flex justify-content-center'>
                { loadingVisible && <LoadingIndicator ref={loadingRef} /> }
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default SubCategoryTemplate
